@import '../../../Assets/scss/base/colors';
@import '../../../Assets/scss/base/fonts';
@import '../../../Assets/scss/base/media';
@import '../../../Assets/scss/base/mixin';
@import '../../../Assets/scss/base/fonts';
@import '../../../Assets/scss/base/animate';
@import '../../../Assets/scss/Constants.scss';

@import '@assets/scss/base/export.scss';

.footer-layout {
	@apply relative mt-auto overflow-visible flex-center text-white z-50;
	height: $footer;
	background: #242725;
	-webkit-box-shadow: 0px 0px 14px 4px rgba(50, 130, 184, 0.1);
	box-shadow: 0px 0px 14px 4px rgba(50, 130, 184, 0.1);

	.social-networks {
		@apply absolute bottom-2.5 flex items-center justify-evenly h-full w-1/4 md:w-1/5;

		@include down(700px) {
			@apply w-1/2 sm:w-1/3;
		}

		.footer-icons-wrapper {
			@apply w-8 p-1.5 rounded-full text-tertiary cursor-pointer transition-all;
			background: #242725;
			-webkit-box-shadow: 0px 0px 5px 2px rgba(15, 76, 117, 0.12);
			box-shadow: 0px 0px 5px 2px rgba(15, 76, 117, 0.12);

			&:hover {
				scale: 1.15;

				-webkit-box-shadow: 0px 0px 5px 2px rgba(29, 123, 185, 0.2);
				box-shadow: 0px 0px 5px 2px rgba(26, 111, 168, 0.2);
			}
		}
	}

	@include theme_mode(light) {
		@apply bg-light;

		.social-networks {
			.footer-icons-wrapper {
				@apply bg-light text-primary;
			}
		}
	}
}
@keyframes halfSpin {
	0% {
		transform: rotate(0);
	}
	25% {
		transform: rotate(-25deg);
	}
	75% {
		transform: rotate(25deg);
	}
	100% {
		transform: rotate(0);
	}
}
