@import '../../Assets/scss/base/colors';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/media';
@import '../../Assets/scss/base/mixin';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/animate';
@import '../../Assets/scss/Constants.scss';

.root-layout {
	@apply flex flex-col h-screen bg-secondary;

	.root-children {
		height: 100%;
	}

	@include theme_mode(light) {
		@apply bg-light;
	}
}
