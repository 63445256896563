@import '../../Assets/scss/base/colors';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/media';
@import '../../Assets/scss/base/mixin';
@import '../../Assets/scss/base/fonts';
@import '../../Assets/scss/base/animate';
@import '../../Assets/scss/Constants.scss';

@import '@assets/scss/base/export.scss';

.neon-btn.rs-btn {
	font-size: 17px;
	@apply transition-all font-medium text-white opacity-70;

	.child {
		min-width: 6rem;
		@apply z-10;
	}

	.fa-icon {
		@apply w-4;
	}

	&.selected {
		@apply text-primary filter brightness-150;

		.fa-icon {
			box-shadow: 0px 0px 10px 3px rgba(59, 231, 190, 0.3), inset 0px 0px 10px 2px rgba(59, 231, 190, 0.3);
			-webkit-box-shadow: 0px 0px 10px 3px rgba(59, 231, 190, 0.3), inset 0px 0px 10px 2px rgba(59, 231, 190, 0.3);
		}
	}

	&.led-mode {
		@apply bg-transparent rounded-none px-4;

		.border-span {
			@apply absolute block w-full h-0.5 from-transparent to-primary transition-all duration-700;

			&.top {
				left: -100%;
				@apply top-0 bg-gradient-to-l;
			}

			&.bottom {
				right: -100%;
				@apply bottom-0 bg-gradient-to-r;
			}
		}

		&:hover {
			@apply rounded-md;
			.border-span {
				&.top {
					left: 100%;
				}

				&.bottom {
					right: 100%;
				}
			}
		}
	}

	&.fill-mode {
		@apply bg-white text-primary w-40 opacity-100;

		.child {
			@apply flex flex-row-reverse w-full justify-center;
		}

		.s-arrow-right {
			@apply opacity-0 transition-all duration-300 mt-0.5 -mr-5 ml-2;
		}

		.filler {
			right: 100%;
			@apply z-0 bg-primary absolute left-0 h-full top-0 transition-all duration-500 rounded-sm;
		}

		&:hover {
			@apply text-white;

			.filler {
				right: 0;
			}

			.s-arrow-right {
				@apply opacity-100 block;
			}
		}

		@include theme_mode(light) {
			@apply bg-tertiary;
		}
	}

	&:not(.led-mode) {
		&:not(.fill-mode) {
			@apply px-14 rounded-xl tracking-wide;
			background: rgb(79, 238, 208);
			background: linear-gradient(325deg, rgba(79, 238, 208, 1) 24%, rgba(39, 224, 173, 1) 73%);

			&:hover {
				box-sizing: border-box;
				@apply transform scale-105;
				background: rgb(79, 238, 208);
				background: linear-gradient(128deg, rgba(79, 238, 208, 1) 24%, rgba(39, 224, 173, 1) 73%);
			}
		}
	}
}

@include up(md) {
	.light-back {
		.neon-btn.rs-btn {
			@apply text-black;
		}
	}
}
