@import 'colors';
@import 'fonts';
@import 'media';
@import 'mixin';
@import 'fonts';
@import 'animate';
@import '../Constants.scss';

@tailwind base;
@tailwind components;
@tailwind utilities;
